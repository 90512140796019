import React from 'react';

import { cn } from '@virginexperiencedays/tailwind';

import { BreadcrumbsIcon } from '../../icons/IconBreadcrumb';

import { LinkWrap } from '../LinkWrap';

export type CrumbType = {
  /**
   * Display name for the breadcrumb
   */
  name: string;
  /**
   * Path/location for the breadcrumb
   */
  path?: string;
  externalLink?: boolean;
  /**
   * To support additonal events during href clicks
   */
  onClick?: () => void;
};

export type BreadcrumbsProps = {
  /**
   * List of breadcrumbs to be displayed
   */
  crumbs: CrumbType[];
  className?: string;
  shallow?: boolean;
  asExternalLinks?: boolean;
};

export const Breadcrumbs = ({
  crumbs,
  className,
  shallow = false,
  asExternalLinks = false,
}: BreadcrumbsProps) => {
  if (!crumbs || crumbs.length < 1) {
    return null;
  }
  return (
    <div className={cn('text-sm leading-none', className)}>
      <ol
        data-testid="breadcrumbs"
        className="hide-scrollbar flex flex-row items-center gap-2 overflow-y-hidden overflow-x-scroll whitespace-nowrap"
      >
        {crumbs.map((crumb, index) => {
          const { name, path, externalLink, onClick } = crumb;
          if (index === crumbs.length - 1) {
            return (
              <li
                key={`breadcrumb-${name}`}
                data-testid="breadcrumb"
                className="flex items-center gap-2"
              >
                {index !== 0 && <Icon />}
                <span>{name}</span>
              </li>
            );
          }
          return (
            <li
              key={`breadcrumb-${name}`}
              data-testid="breadcrumb"
              className="text-link flex items-center gap-2 hover:underline"
            >
              {index !== 0 && <Icon />}

              {externalLink || asExternalLinks ? (
                <LinkWrap
                  href={path}
                  onClick={onClick}
                  isExternal
                  shallow={shallow}
                  target={asExternalLinks ? '_self' : null}
                >
                  {name}
                </LinkWrap>
              ) : (
                <LinkWrap href={path} onClick={onClick} prefetch={false} shallow={shallow}>
                  {name}
                </LinkWrap>
              )}
            </li>
          );
        })}
      </ol>
    </div>
  );
};

const Icon = () => (
  <span className="py-1">
    <BreadcrumbsIcon />
  </span>
);

type ByPassBlockProps = {
  mainContentId: string;
};

export const ByPassBlock = ({ mainContentId }: ByPassBlockProps) => {
  return (
    <a
      href={`#${mainContentId}`}
      tabIndex={1}
      className="bg-background-primary font-inter fixed left-0.5 top-0.5 translate-y-[-120%] border-0 px-2 py-1 text-sm leading-normal text-white no-underline transition-transform focus:translate-y-0 focus:outline-2 focus:outline-black"
    >
      Skip To Main Content
    </a>
  );
};

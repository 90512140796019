import config from '../structured_data.config.json';
import { FAQItemType } from '../types';

export const createFaqPage = ({ urlSlug, items }: { urlSlug: string; items: FAQItemType[] }) => {
  if (!items?.length) return null;

  const { website } = config;

  const pageUrl = `${website.url?.replace(/\/$/, '')}/${urlSlug}`;

  const itemList = (items: FAQItemType[]) =>
    items.map(({ question, answer }) => ({
      '@type': 'Question',
      name: question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: answer,
      },
    }));

  const mainEntity = itemList(items);

  return {
    '@type': 'FAQPage',
    '@id': `${pageUrl}#faqs`,
    mainEntity,
  };
};

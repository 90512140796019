import { addToDataLayer } from '../utils';

export default ({ action, label, category }) => {
  addToDataLayer({
    event: 'GAEvent',
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
  });
};

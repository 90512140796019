import { SVGProps } from 'react';

export const BreadcrumbsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={6}
    height={8}
    viewBox="0 0 6 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.834 7.766a.8.8 0 010-1.132L3.47 4 .834 1.366A.8.8 0 011.966.234l3.2 3.2a.8.8 0 010 1.132l-3.2 3.2a.8.8 0 01-1.132 0z"
      fill="#93979C"
    />
  </svg>
);

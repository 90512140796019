/**
 *
 * Method that removes properties from the object that are in the options array
 * @param object - The object to filter
 * @param excludedKeys(optional) - String array where each options defines a key to filter
 * @returns object filtered by options keys
 */

export const filterObjectByKey = (object, excludedKeys?: string[]) => {
  if (!excludedKeys) {
    return object;
  }
  const objectArray = Object.entries(object);
  const filteredObjArray = objectArray.filter(([key]) => !excludedKeys.includes(key));
  // DO NOT USE Object.fromEntries() as it's not supported in iOS12 (iPhone 7) / Samsung Galaxy S10(Samsung Internet)
  // below is actually a polyfill for Object.fromEntries()
  return filteredObjArray.reduce((obj, [key, val]) => {
    obj[key] = val;
    return obj;
  }, {});
};

import type { SliceZone as SliceZoneType } from '@prismicio/types';
import { Product } from '@virginexperiencedays/products';
import { createLinkedPath, getPrismicLinkPath } from '@virginexperiencedays/header-2024/src';
import type {
  CategoryLinkType,
  CustomCardType,
  TTDBlogPostLinkType,
} from '@virginexperiencedays/cms/types/slices';

import { impression } from '../../../tracking/gtm/utils';
import { default as productClick } from '../../../tracking/gtm/interactions/clickProduct';
import { default as gaEvent } from '../../../tracking/gtm/events/GAEvent';

import {
  getTrackingData,
  handleProductCardImageChange,
  trackProductCardV2Click,
} from '../ProductCard/tracking';
import { slice as trackSlice } from './interactions';

type BaseClickTracking = {
  slug: string;
  next: (url: string) => void;
  hasGAEvent?: boolean;
  pageType?: string; // slice tracking
  trackingPageType?: string; // product card tracking
  isProductCardsV2?: boolean;
};

type ClickTrackingProps = BaseClickTracking & {
  slices: SliceZoneType;
};

type ProductTrackingProps = BaseClickTracking & {
  slice: {
    slice_type: string;
    variation: string;
    items: any[];
    primary: any;
  };
};

/**
 * Mutates slices to avoid including tracking code in the CMS code
 * Dangerous because this can cause unwanted side-effects and make things difficult to debug
 * */
export const bind = ({
  slices,
  slug,
  next,
  hasGAEvent = true,
  pageType = 'Category',
  isProductCardsV2 = false,
  trackingPageType,
}: ClickTrackingProps): any => {
  return (
    slices?.map((slice) => {
      switch (slice.variation) {
        case 'cardsProductIDs': //  falls through
        case 'cardsProductsAlgolia':
          return products({
            slice,
            slug,
            next,
            pageType,
            isProductCardsV2,
            trackingPageType,
          });
        case 'cardsCategoryLinks':
          return categories({
            slice,
            slug,
            next,
            hasGAEvent,
            pageType,
          });
        case 'cardsPostLinks':
          return posts({ slice, slug, next: (slug: string) => next(`${slug}`), pageType });
        case 'cardsCustomContent':
          return custom({
            slice,
            slug,
            next: (url: string) => (global.window.location.href = url),
            pageType,
          });
      }

      return slice;
    }) ?? []
  );
};

function products({
  slice,
  slug,
  next,
  pageType,
  trackingPageType,
  isProductCardsV2,
}: ProductTrackingProps) {
  const mapItems = (items) =>
    items.map((item: Product, index: number) => {
      const trackingData = getTrackingData({
        item,
        tracking: { pageType: trackingPageType, module: slice?.slice_type },
        index,
      });

      return {
        ...item,
        onImageCarouselSwipe: (props) => handleProductCardImageChange({ ...props, trackingData }),
        onImageCarouselNavClick: (props) =>
          handleProductCardImageChange({ ...props, trackingData }),
        onClick: (e: any) => {
          e.preventDefault();

          // tracking
          isProductCardsV2 && trackProductCardV2Click(e, { trackingData });
          productClick({
            list: `${slug} | Carousel | ${slice.primary?.title ?? 'Products'}`,
            product: impression(
              item,
              index,
              `${slug} | Carousel | ${slice.primary?.title ?? 'Products'}`
            ),
          });
          trackSlice({
            slug,
            label: item.title,
            sliceType: slice.slice_type,
            variation: slice.variation,
            cardType: slice.primary?.card_type,
            pageType,
          });

          const productUrl = item.isCollectionProduct
            ? `/collection/${item.slug}`
            : `/product/${item.slug}`;

          next(productUrl);
        },
      };
    });

  const items =
    slice.slice_type === 'carousel_with_tabs'
      ? slice.items.map((categoryItems) => ({
          ...categoryItems,
          items: mapItems(categoryItems.items),
        }))
      : mapItems(slice.items);

  return {
    ...slice,
    items,
  };
}

function categories({ slice, slug, next, hasGAEvent, pageType }: ProductTrackingProps) {
  return {
    ...slice,
    items: slice.items.map((item: CategoryLinkType) => ({
      ...item,
      onClick: (e: any) => {
        e.preventDefault();
        const label = item?.hero_title ?? item.content_link?.data?.title;

        // tracking
        if (hasGAEvent) {
          gaEvent({
            action: slug,
            category: 'Category Page Promos',
            label,
          });
        }
        trackSlice({
          slug,
          label,
          sliceType: slice.slice_type,
          variation: slice.variation,
          cardType: slice.primary?.card_type,
          pageType,
        });

        next(createLinkedPath(item.content_link.type, item.content_link?.data.uid));
      },
    })),
  };
}

function posts({ slice, slug, next, pageType }: ProductTrackingProps) {
  return {
    ...slice,
    items: slice.items.map((item: TTDBlogPostLinkType) => ({
      ...item,
      onClick: (e: any) => {
        e.preventDefault();
        const label = item?.title_override ?? item?.content_link?.data.title;

        // tracking
        trackSlice({
          slug,
          label,
          sliceType: slice.slice_type,
          variation: slice.variation,
          cardType: slice.primary?.card_type,
          pageType,
        });

        next(createLinkedPath(item.content_link.type, item.content_link?.data.uid));
      },
    })),
  };
}

function custom({ slice, slug, next, pageType }: ProductTrackingProps) {
  return {
    ...slice,
    items: slice.items.map((item: CustomCardType) => ({
      ...item,
      onClick: (e: any) => {
        e.preventDefault();
        const label = item?.hero_title ?? item?.title;

        // tracking
        gaEvent({
          action: slug,
          category: 'Category Page Promos', // This is wrong because we can't be sure that these will be categories, but Craig wants it this way because it's a legacy thing
          label,
        });
        trackSlice({
          slug,
          label,
          sliceType: slice.slice_type,
          variation: slice.variation,
          cardType: slice.primary?.card_type,
          pageType,
        });

        next(getPrismicLinkPath(item.url));
      },
    })),
  };
}

export function createBreadcrumbsList({ websiteUrl, baseUrl, urlSlug, breadcrumbs }) {
  return {
    '@type': 'BreadcrumbList',
    '@id': `${new URL(`${baseUrl}/${urlSlug}`, websiteUrl)}#breadcrumb`,
    itemListElement: breadcrumbs
      .filter(({ name, url }) => name && url)
      .map(({ name, url }, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        name,
        item: new URL(url, websiteUrl),
      })),
  };
}

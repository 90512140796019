import { createBreadcrumbsList } from '../utils/createBreadcrumbsList';
import { createFaqPage } from '../utils/createFaqPage';
import { filterObjectByKey } from '../utils/filterObjectByKey';
import { imageObject } from '../utils/imageObject';
import type { FAQItemType, ListItemType } from '../types';

import config from '../structured_data.config.json';

const { website, organization } = config;

export type StructuredDataProps = {
  page: {
    uid: string;
    first_publication_date: string;
    last_publication_date: string;
  };
  title: string;
  description: string;
  breadcrumbs?: ListItemType[];
  image?: {
    url: string;
    dimensions: {
      width: string;
      height: string;
    };
  };
  faqs?: FAQItemType[];
};

// Misc pages are pages that are not archive/category, homepage, blog article, or product
// e.g. Custom Site Page
function getStructuredData({
  page,
  title,
  description,
  breadcrumbs,
  image,
  faqs,
}: StructuredDataProps) {
  const includeImage = !!image;
  const includeFaqs = faqs?.length > 0;
  const includeBreadcrumbs = breadcrumbs?.length >= 3;

  const {
    uid: urlSlug,
    first_publication_date: datePublished,
    last_publication_date: dateModified,
  } = page;

  const heroImage = includeImage && {
    url: image.url,
    width: image.dimensions.width,
    height: image.dimensions.height,
  };

  return JSON.stringify({
    '@context': 'https://schema.org',
    '@graph': [
      // organization: extract type and name
      (({ '@type': type, name, '@id': id }) => ({ '@type': type, name, '@id': id }))(organization),

      // website: excluding potentialAction
      filterObjectByKey(website, ['potentialAction']),

      // only include if there is hero image
      ...(includeImage ? [imageObject({ image: heroImage })] : []),

      // only include if there is faq accordion group
      ...(includeFaqs ? [createFaqPage({ urlSlug, items: faqs })] : []),

      // only include if the page has a parent page other than the homepage (must be at least 3 levels)
      ...(includeBreadcrumbs
        ? [createBreadcrumbsList({ websiteUrl: website.url, baseUrl: '', urlSlug, breadcrumbs })]
        : []),

      {
        '@type': 'WebPage',
        '@id': `https://www.virginexperiencedays.co.uk/${urlSlug}#webpage`,
        url: `http://www.virginexperiencedays.co.uk/${urlSlug}`,
        // page h1 or meta title
        name: title,
        // meta description
        description,
        datePublished,
        dateModified,
        inLanguage: website.inLanguage,
        isPartOf: {
          '@id': 'https://www.virginexperiencedays.co.uk/#website',
        },
        ...(includeFaqs
          ? {
              hasPart: {
                '@id': `https://www.virginexperiencedays.co.uk/${urlSlug}#faqs`,
              },
            }
          : {}),
        ...(includeImage
          ? {
              primaryImageOfPage: {
                '@id': image.url,
              },
            }
          : {}),
        ...(includeBreadcrumbs
          ? {
              breadcrumb: {
                '@id': `https://www.virginexperiencedays.co.uk/${urlSlug}#breadcrumb`,
              },
            }
          : {}),
      },
    ],
  });
}

export default getStructuredData;

import { useRouter } from 'next/compat/router';
import { SliceZone } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';

import { ByPassBlock } from '@virginexperiencedays/components-v2/src/ByPassBlock';
import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
import { Stack } from '@virginexperiencedays/components-v2/src/layout/Stack';
import { slugToName } from '@virginexperiencedays/search/utils/category';
import { submit as onNewsletterSubmit } from '../../../utils/newsletter';

import getStructuredData from '../../SEO/StructuredData/miscPage';
import { DyContextPageType } from '@virginexperiencedays/dy';
import { GLOBAL_SETTINGS, PAGE_TYPES } from '../../tracking/gtm/consts';
import { getPageMetaData } from '../../SEO/utils/metadata';
import { pageView as trackFbPageView } from '../../tracking/fb/events';
import { useIsProductCardsV2 } from '../search/ProductCard';
import { bind as dangerouslyBindClickTracking } from '../search/tracking/clicks';
import { slice as trackSlice } from '../search/tracking/interactions';
import { useTracking } from '../../tracking/useTracking';
import { useValidB2bRoute } from '../../../utils/b2b/useValidB2bRoute';

import AccordionGroup from '@virginexperiencedays/cms/slicemachine/AccordionGroup';
import Button from '@virginexperiencedays/cms/slicemachine/Button';
import CarouselStructure from '@virginexperiencedays/cms/slicemachine/CarouselStructure';
import CarouselWithIntroSlot from '@virginexperiencedays/cms/slicemachine/CarouselWithIntroSlot';
import CarouselWithTabs from '@virginexperiencedays/cms/slicemachine/CarouselWithTabs';
import ChunkyMessage from '@virginexperiencedays/cms/slicemachine/ChunkyMessage';
import ColumnStructure from '@virginexperiencedays/cms/slicemachine/ColumnStructure';
import CorporateLeadForm from '@virginexperiencedays/cms/slicemachine/CorporateLeadForm';
import CustomImage from '@virginexperiencedays/cms/slicemachine/CustomImage';
import FaqAccordionGroup from '@virginexperiencedays/cms/slicemachine/FaqAccordionGroup';
import FeaturedVisualCards from '@virginexperiencedays/cms/slicemachine/FeaturedVisualCards';
import GiftCardPriceSelector from '@virginexperiencedays/cms/slicemachine/GiftCardPriceSelector';
import GiftCardSlider from '@virginexperiencedays/cms/slicemachine/GiftCardSlider';
import GiftCardUsp from '@virginexperiencedays/cms/slicemachine/GiftCardUsp';
import GridStructure from '@virginexperiencedays/cms/slicemachine/GridStructure';
import HeroStyle02 from '@virginexperiencedays/cms/slicemachine/HeroStyle02';
import HeroStyle03 from '@virginexperiencedays/cms/slicemachine/HeroStyle03';
import HeroStyle04 from '@virginexperiencedays/cms/slicemachine/HeroStyle04';
import HeroStyle05 from '@virginexperiencedays/cms/slicemachine/HeroStyle05';
import HtmlEmbed from '@virginexperiencedays/cms/slicemachine/HtmlEmbed';
import IconBanners from '@virginexperiencedays/cms/slicemachine/IconBanners';
import ImageRow from '@virginexperiencedays/cms/slicemachine/ImageRow';
import ListOfLinks from '@virginexperiencedays/cms/slicemachine/ListOfLinks';
import MegaNuggets from '@virginexperiencedays/cms/slicemachine/MegaNuggets';
import Newsletter from '@virginexperiencedays/cms/slicemachine/Newsletter';
import Nugget from '@virginexperiencedays/cms/slicemachine/Nugget';
import PromoBanner from '@virginexperiencedays/cms/slicemachine/PromoBanner';
import RichText from '@virginexperiencedays/cms/slicemachine/RichText';
import Spacer from '@virginexperiencedays/cms/slicemachine/Spacer';
import Title from '@virginexperiencedays/cms/slicemachine/Title';
import UspGrid from '@virginexperiencedays/cms/slicemachine/UspGrid';

import Breadcrumbs from '../../Breadcrumbs';
import DynamicYield from '../../DynamicYield/DynamicYield';
import Seo from '../../SEO/SEO';

import type { BasePageProps as PageProps } from '../types';

const mainContentId = 'custom-site-page-content';

const Page = ({ isDarkTheme, prismicPage, prismicSlices, slug, listOfLinks }: PageProps) => {
  const isProductCardsV2 = useIsProductCardsV2();
  const router = useRouter();
  // Since Custom Site Pages don't have Page Title field, will use Page Name which Prismic returns as slug
  const pageTitle = slugToName(slug);
  const pageType = prismicPage?.data?.page_type ?? null;
  const pathname = router.pathname;

  const seo = getPageMetaData({
    ...prismicPage.data,
    meta_title: prismicPage?.data?.meta_title || pageTitle,
    canonical: prismicPage?.data?.canonical || `${process.env.NEXT_PUBLIC_HOME_LINK}/${slug}`,
    metaTitleSuffixed: true,
  });

  const getFirstH1 = () => {
    if (typeof window !== 'undefined') {
      return document.getElementsByTagName('h1')?.[0]?.innerText;
    }
    return null;
  };

  // Structured Data
  // only get first hero image
  const image = prismicPage.data.slices?.find(({ slice_type }) => slice_type === 'hero_style01')
    ?.primary?.image;
  // get all faq groups and merge into one
  const faqs = prismicPage.data.slices
    ?.filter(({ slice_type }) => slice_type === 'faq_accordion_group')
    ?.flatMap(({ items }) =>
      items?.map((item) => ({
        question: item.heading,
        answer: prismicH.asHTML(item.content),
      }))
    );
  const structuredData = getStructuredData({
    page: prismicPage,
    title: getFirstH1() || seo.meta_title,
    description: seo.meta_description,
    image,
    faqs,
  });

  const isSlicesExisting = !!prismicSlices?.length;

  // stores slug in storage if B2B
  useValidB2bRoute(slug);

  useTracking({
    legacy: { pageName: pageTitle, pageType },
    gtm: { initial: [GLOBAL_SETTINGS, { ecommerce: null }] },
    fb: () => {
      trackFbPageView({
        name: pageTitle,
      });
    },
  });

  prismicSlices = dangerouslyBindClickTracking({
    slices: prismicSlices,
    slug,
    next: (url) => router.push(url),
    pageType,
    trackingPageType: PAGE_TYPES.CustomSite,
    isProductCardsV2,
  });

  return (
    <>
      <Seo {...seo} structuredData={structuredData} />
      <DynamicYield type={DyContextPageType.OTHER} data={pathname ?? []} />
      <Container>
        <Breadcrumbs asExternalLinks crumbs={[{ name: 'Home', path: '/' }, { name: pageTitle }]} />
      </Container>
      {isSlicesExisting && (
        <>
          <ByPassBlock mainContentId={mainContentId} />
          <Stack id={mainContentId}>
            <SliceZone
              slices={prismicSlices}
              components={{
                accordion_group: AccordionGroup,
                button: Button,
                carousel_structure: CarouselStructure,
                carousel_with_intro_slot: CarouselWithIntroSlot,
                carousel_with_tabs: CarouselWithTabs,
                chunky_message: ChunkyMessage,
                column_structure: ColumnStructure,
                corporate_lead_form: CorporateLeadForm,
                faq_accordion_group: FaqAccordionGroup,
                featured_visual_cards: FeaturedVisualCards,
                gift_card_price_selector: GiftCardPriceSelector,
                gift_card_slider: GiftCardSlider,
                gift_card_usp: GiftCardUsp,
                grid_structure: GridStructure,
                hero_style02: HeroStyle02,
                hero_style03: HeroStyle03,
                hero_style04: HeroStyle04,
                hero_style05: HeroStyle05,
                html_embed: HtmlEmbed,
                icon_banners: IconBanners,
                image_row: ImageRow,
                image_slice: CustomImage,
                list_of_links: ListOfLinks,
                mega_nuggets: MegaNuggets,
                newsletter: Newsletter,
                nugget: Nugget,
                promo_banner: PromoBanner,
                rich_text: RichText,
                spacer: Spacer,
                title: Title,
                usp_grid: UspGrid,
              }}
              context={{
                router,
                slug,
                lastSlug: slug,
                hide: false,
                pageTitle,
                pageType,
                isDarkTheme,
                isProductCardsV2,
                listOfLinks,
                trackSlice,
                onNewsletterSubmit,
                overridesSlugInfo: null,
              }}
            />
          </Stack>
        </>
      )}
    </>
  );
};

export default Page;

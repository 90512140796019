export function imageObject({ image }) {
  return image
    ? {
        '@type': 'ImageObject',
        '@id': image.url,
        url: image.url,
        width: image.width,
        height: image?.height || image.width,
      }
    : null;
}
